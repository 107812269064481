nav {
  @apply flex justify-between items-start fixed z-[9999999] top-0 left-0 w-full px-10 lg:px-32 py-6 text-white;
}

tspan {
  color: white;
  fill: white;
}

footer {
  @apply h-max px-10 py-32 lg:p-32 bg-black text-white grid lg:grid-cols-2 gap-x-2 gap-y-14;
}

button {
  @apply transition-colors duration-300;
}

.underline-link {
  position: relative;
  @apply py-2;

  &::after {
    @apply transition-all duration-300 bg-blue-500;
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 2px;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }
}
