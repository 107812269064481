* {
  box-sizing: border-box;

  &::after,
  &::before {
    box-sizing: inherit;
  }

  @apply transition-all duration-300 ease-in-out;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.hero-text {
  opacity: 0;
}
